import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Contact from "../pages/Contact"

const Projects = () => {
    return (
        <div>
            <Navbar/>
            <div className="projectPageWrapper container">
                <div>
                    <div>
                        <h2 className="sectionHeader headingTextDecor fontColorGreen">Projects</h2>
                    </div>
                    <div className="projectPageSection">
                        <div>
                            <h3 className="sectionSubHeader headingTextDecor fontColorGreen">Live Sites</h3>
                            <p>Here are a few live sites I have built and currently maintaining.</p>
                        </div>
                        <div className="projectSectionContent">
                            <a href="https://www.defensetherapy.com/" target="_blank" rel="noopener noreferrer">
                            <img class="portfolioImgSize" src="/images/DefenseTherapy_bubble.png" alt="Birthday Everyday 365 Website"></img>
                            </a>
                        </div>
                    </div>
                    <div className="projectPageSection">
                        <div>
                            <h3 className="sectionSubHeader headingTextDecor fontColorGreen">In Progress</h3>
                            <p>Check out some of the projects I am currently working on.</p>
                        </div>
                        <div className="projectSectionContent">
                            <a 
                            href="https://www.figma.com/proto/SVygaecLPpEpOGrsTTN3qm/Secure-Fingerprints---Nick?page-id=0%3A1&node-id=1-2&viewport=289%2C254%2C0.72&t=CiJAKhJB18jMV1LC-1&scaling=scale-down-width&content-scaling=fixed" 
                            target="_blank" 
                            rel="noopener noreferrer">
                                <img class="portfolioImgSize" src="/images/SecureFingerprints_bubble.png" alt="Secure Fingerprints Figma design"></img>
                            </a> 
                            <a 
                            href="https://www.figma.com/proto/wNMjvZF9CMQMWbW76FoQiS/Safe-1st-Training---Kenny?page-id=0%3A1&node-id=4-55&node-type=frame&viewport=-114%2C269%2C0.17&t=Yis8Qj1JWY1oZkyF-1&scaling=scale-down-width&content-scaling=fixed" 
                            target="_blank" 
                            rel="noopener noreferrer">
                                <img class="portfolioImgSize" src="/images/SafetyFirstTraining_bubble.png" alt="Safety 1stTraining Figma design"></img>
                            </a>
                            <a 
                            href="https://www.figma.com/proto/CfprUhN2dFkdM8OT0wagS1/Select-Fire.org---Dirk-%26-Otis?page-id=0%3A1&node-id=3-48&node-type=frame&viewport=198%2C139%2C0.25&t=Qaa1MITKnL4YqjkC-1&scaling=scale-down-width&content-scaling=fixed" 
                            target="_blank" 
                            rel="noopener noreferrer">
                                <img class="portfolioImgSize" src="/images/SelectFire_bubble.png" alt="Select Fire Figma design"></img>
                            </a> 
                            <a 
                                href="https://birthdayeveryday365.com/" 
                                target="_blank" 
                                rel="noopener noreferrer">
                                <img class="portfolioImgSize" src="/images/BE365_bubble.png" alt="Birthday Everyday 365 Website"></img>
                            </a> 
                        </div>
                    </div>
                </div>
            </div>
            
                <Contact/>
            

           
                <Footer/>
            
        </div>
    )
}
export default Projects;